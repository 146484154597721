<template>
	<div id="huizhen">
		<div class="hzCont">
			<div class="list">
				<h1>历史会议</h1>
				<div class="item" v-for="(item,i) in meetingList" :key="i" @click="toHuiyiDetails(item)">
					<div class="tags">
						<div class="type" v-if="item.meetingType=='患教会'">
							<img :src="require('@/assets/imgs/video.png')">患教会
						</div>
						<div class="type" v-if="item.meetingType=='研讨会'">
							<img :src="require('@/assets/imgs/video2.png')">研讨会
						</div>
						<div class="idNum">编号：{{item.id}}</div>
					</div>
					<div class="main">
						<div class="left">
							<div class="tit">主题：{{item.meetingTitle}}</div>
							<div class="project">所属项目：{{item.projectName}}</div>
							<div class="time">
								会议时间：
								{{$moment(item.meetingStartTime).format('L') }}
								{{$moment(item.meetingStartTime).format('LT') }}
							</div>
							<div :class="`type ${item.meetingStatus}`" v-if="item.meetingStatus == 'WAIT'">待开始</div>
							<div :class="`type ${item.meetingStatus}`" v-if="item.meetingStatus == 'FINISH'">已结束</div>
							<div :class="`type ${item.meetingStatus}`" v-if="item.meetingStatus == 'LIVING'">进行中</div>
						</div>
						<div class="opts">
							<el-button class="details" @click.stop="toHuiyiDetails(item)">会议详情</el-button>
							<el-button class="agreement" v-if="item.isSign == 'NO'"
								@click.stop="toagreement(item)">签署协议</el-button>
						</div>
					</div>
				</div>
				<div class="null" v-if="!meetingList[0]">
					<img src="../../assets/imgs/EmptyState.png" alt="">
					<p>未查询到历史会议记录</p>
				</div>
			</div>
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="size"
				:current-page.sync="page" @current-change="getMyMeeting" :hide-on-single-page="false"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		doctorMeetList,
		doctorTodayMeeting,
		doctorGetMeetingUrl
	} from '@/request/api.js';
	export default {
		name: 'huizhen',
		components: {},
		data() {
			return {
				page: 1,
				size: 10,
				total: 0,
				curNow: "FINISH",
				meetingList: [],
				todayMeet: '',
				list: [{
						name: '待参会',
						class: 'WAIT',
					},
					{
						name: '已结束',
						class: 'FINISH',
					}
				],
				message: ''
			};
		},
		created() {
			this.getMyMeeting();
		},
		mounted() {},
		methods: {
			getMyMeeting() {
				let data = {
					current: this.page,
					size: this.size,
					meetingStatus: this.curNow,
				};
				doctorMeetList(data).then(res => {
					if (res.code == 200) {
						this.meetingList = res.data.records;
						this.total = res.data.total;
					};
				});
				doctorTodayMeeting().then(res => {
					if (res.code == 200) {
						this.todayMeet = res.data;
					};
				});
			},
			// 进入会议
			enterTheMeeting() {
				let data = {
					meetingId: this.todayMeet.id
				};
				doctorGetMeetingUrl(data).then(res => {
					if (res.code == 200) {
						window.open(res.data)
					};
				});
			},
			enterMeeting(item) {
				let data = {
					meetingId: item.id
				};
				doctorGetMeetingUrl(data).then(res => {
					if (res.code == 200) {
						window.open(res.data)
					};
				});
			},
			// 跳转会诊详情
			toHuiyiDetails(item) {
				this.$router.push({
					name: 'dmeetdetail',
					query: {
						mId: item.id
					}
				});
			},
			// 去签署协议
			toagreement(item) {
				this.$router.push({
					name: 'agreement',
					query: {
						mId: item.id
					}
				});
			},
			// 切换会诊选项
			tabsChange(item) {
				this.curNow = item.class;
				this.status = item.meetingStatus;
				this.getMyMeeting();
			},
		}
	}
</script>

<style lang="scss" scoped>
	#huizhen {
		display: flex;
		flex-direction: column;

		.hzBanner {
			min-height: 140px;
			background-color: rgba(20, 71, 205, 1);
		}

		.hzCont {
			flex: 1;
			padding: 20px;
			display: flex;
			background-color: #fff;
			flex-direction: column;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;

			.tabs {
				display: flex;
				margin-bottom: 20px;
				flex-direction: row;
				align-items: center;
				padding-bottom: 20px;
				justify-content: space-around;
				border-bottom: 2px solid #f3f3f3;

				.el-divider {
					width: 2px;
					height: 30px;
				}

				p {
					padding: 5px;
					font-size: 18px;
					cursor: pointer;
					line-height: 2.5;
					position: relative;

					img {
						width: 0;
						left: 50%;
						bottom: 0;
						height: 0;
						position: absolute;
						transition: all .3s;
						transform: translate(-50%, 50%);
					}
				}

				.active img {
					width: 60px;
					height: 24px;
				}
			}

			.list {
				flex: 1;

				h1 {
					color: #000;
					font-size: 20px;
					margin-bottom: 20px;
				}

				.el-carousel {
					margin-bottom: 10px;
				}

				.el-carousel__item {
					display: flex;
					flex-direction: row;

					.bgImg {
						flex: 1;
						height: 100%;
						overflow: hidden;
						object-fit: cover;
						border-radius: 5px;

						img {
							width: 100%;
							height: 100%;
							display: block;
							object-fit: cover;
						}
					}

					.main {
						flex: 1;
						padding: 20px 0 20px 40px;
						display: flex;
						flex-direction: column;
						align-items: space-between;
						justify-content: space-between;

						>div {
							font-size: 16px;
							display: flex;
							align-items: center;
							color: #112950;

							img {
								height: 16px;
								margin-right: 5px;
							}
						}

						.tit {
							font-size: 22px;
							color: #000;
						}

						.goLive {
							color: #fff;
							height: 50px;
							background-color: #D43030;
						}
					}
				}

				.itemToday {
					display: flex;
					margin-bottom: 20px;
					flex-direction: row;
					padding: 20px;
					box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
					transition: all .3s;
					border-radius: 10px;
					align-items: center;

					&:hover {
						background-color: #E8EEFC;
					}

					.main {
						flex: 1;
						display: flex;
						flex-direction: column;

						.tag {
							display: flex;
							margin-bottom: 10px;

							span {
								height: 20px;
								background-color: #007AFF;
								font-size: 12px;
								border-radius: 5px;
								color: #fff;
								display: flex;
								align-items: center;
								padding: 0 10px;
							}
						}

						.tit {
							font-size: 16px;
							color: #000;
							margin-bottom: 10px;
							line-height: 1;
						}

						.time {
							font-size: 13px;
							color: #007AFF;
							line-height: 1;
						}
					}

					.goLive {
						background: #0065FF;
						color: #fff;
						padding: 0 25px;
						height: 28px;
					}
				}

				.item {
					display: flex;
					margin-bottom: 20px;
					flex-direction: column;
					padding: 20px;
					box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
					transition: all .3s;
					border-radius: 10px;
					cursor: pointer;

					* {
						cursor: pointer;
					}

					&:hover {
						background-color: #E8EEFC;
					}

					.tags {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 10px;

						.type {
							display: flex;
							align-items: center;
							color: #000;
							font-size: 12px;

							img {
								margin-right: 5px;
								width: 20px;
								height: 15px;
							}
						}

						.idNum {
							font-size: 12px;
							color: #999;
						}
					}

					.main {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						.left {
							.tit {
								font-size: 18px;
								color: #000;
								margin-bottom: 10px;
								line-height: 1;
							}

							.time,
							.project {
								font-size: 14px;
								color: #666;
								line-height: 1;
								margin-bottom: 10px;
							}

							.type {
								display: flex;
								color: #2A82E4;
							}

							.FINISH {
								color: #999;
							}

							.LIVING {
								color: green;
							}
						}

						.opts {
							display: flex;
							align-items: flex-end;

							.details {
								background-color: #007AFF;
								color: #fff;
								padding: 0 25px;
								height: 28px;
							}

							.agreement {
								background-color: #D43030;
								color: #fff;
								padding: 0 25px;
								height: 28px;
							}
						}
					}
				}

				.null {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 100%;
					border-radius: 10px;
					padding: 20px;
					box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
					margin-bottom: 20px;

					img {
						width: 200px;
					}

					p {
						font-size: 18px;
						color: #A6A6A6;
					}
				}
			}

			.el-pagination {
				margin: auto;
			}
		}
	}
</style>